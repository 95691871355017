import gallery1 from "../textiles/1.jpg";
import gallery2 from "../textiles/2.jpg";
import gallery3 from "../textiles/3.jpg";
import gallery4 from "../textiles/4.jpg";
import gallery5 from "../textiles/5.jpg";
import gallery6 from "../textiles/6.jpg";
import gallery7 from "../textiles/7.jpg";
import gallery8 from "../textiles/8.jpg";
import gallery9 from "../textiles/9.jpg";
import gallery10 from "../textiles/10.png";
import gallery11 from "../textiles/11.jpg";
import gallery12 from "../textiles/12.jpg";

const DataGallery = [
  { id: 1, image: gallery1 },
  { id: 2, image: gallery2 },
  { id: 3, image: gallery3 },
  { id: 4, image: gallery4 },
  { id: 5, image: gallery5 },
  { id: 6, image: gallery6 },
  { id: 7, image: gallery7 },
  { id: 8, image: gallery8 },
  { id: 9, image: gallery9 },
  { id: 10, image: gallery10 },
  { id: 11, image: gallery11 },
  { id: 12, image: gallery12 },
];

export default DataGallery;
