const ProductsData = [
  { id: 1, category: "T-shirt", image: "/products/t-shirt/tshirt1.jpg" },
  { id: 2, category: "T-shirt", image: "/products/t-shirt/tshirt2.jpg" },
  { id: 3, category: "T-shirt", image: "/products/t-shirt/tshirt3.jpg" },
  { id: 4, category: "T-shirt", image: "/products/t-shirt/tshirt4.jpg" },
  { id: 5, category: "T-shirt", image: "/products/t-shirt/tshirt5.jpg" },
  { id: 6, category: "T-shirt", image: "/products/t-shirt/tshirt6.jpg" },
  { id: 7, category: "Polo's", image: "/products/polos/polo1.jpg" },
  { id: 8, category: "Polo's", image: "/products/polos/polo2.jpg" },
  { id: 9, category: "Polo's", image: "/products/polos/polo3.jpg" },
  { id: 10, category: "Polo's", image: "/products/polos/polo4.jpg" },
  {
    id: 11,
    category: "Sweats, Pants & Rugby's",
    image: "/products/sweats/sweats1.jpg",
  },
  {
    id: 12,
    category: "Sweats, Pants & Rugby's",
    image: "/products/sweats/sweats2.jpg",
  },
  { id: 13, category: "Shirts", image: "/products/shirts/shirt1.jpg" },
  { id: 14, category: "Shirts", image: "/products/shirts/shirt2.jpg" },
  { id: 15, category: "Shirts", image: "/products/shirts/shirt3.jpg" },
  { id: 16, category: "Shirts", image: "/products/shirts/shirt4.jpg" },
  { id: 17, category: "Polar Fleece", image: "/products/polar/polar1.jpg" },
  { id: 18, category: "Polar Fleece", image: "/products/polar/polar2.jpg" },
  { id: 19, category: "Jackets", image: "/products/jacket/jacket1.jpg" },
  { id: 20, category: "Jackets", image: "/products/jacket/jacket2.jpg" },
  {
    id: 21,
    category: "Underwear & Socks",
    image: "/products/underwear/shocks1.jpg",
  },
  {
    id: 22,
    category: "Underwear & Socks",
    image: "/products/underwear/underwear1.jpg",
  },
  { id: 23, category: "Caps", image: "/products/caps/cap1.jpg" },
  { id: 24, category: "Caps", image: "/products/caps/cap2.jpg" },
  {
    id: 25,
    category: "Kids,Toddler & Babywear",
    image: "/products/kids/kid1.jpg",
  },
  {
    id: 26,
    category: "Kids,Toddler & Babywear",
    image: "/products/kids/kid2.jpg",
  },
  {
    id: 27,
    category: "Kids,Toddler & Babywear",
    image: "/products/kids/kid3.jpg",
  },
  { id: 28, category: "Sports", image: "/products/sports/sport1.jpg" },
  { id: 29, category: "Sports", image: "/products/sports/sport2.jpg" },
  { id: 30, category: "Workwear", image: "/products/workwear/workwear1.jpg" },
  { id: 31, category: "Workwear", image: "/products/workwear/workwear2.jpg" },
  { id: 32, category: "Workwear", image: "/products/workwear/workwear3.jpg" },
];

export default ProductsData;
