const FactoryData = [
  { id: 1, title: "Cotton", image: "/factory/cotton.png" },
  { id: 2, title: "Knitting", image: "/factory/knitting.png" },
  {
    id: 3,
    title: "Laboratory",
    image: "/factory/laboratory.png",
  },
  {
    id: 4,
    title: "Washing & Piece dyeing",
    image: "/factory/washing.png",
  },
  { id: 5, title: "Dye Process", image: "/factory/dye.png" },
  { id: 6, title: "CAD Style", image: "/factory/cad.png" },
  { id: 7, title: "Cutting", image: "/factory/cutting.png" },
  { id: 8, title: "Sewing", image: "/factory/sewing.png" },
  {
    id: 9,
    title: "Screen Printing",
    image: "/factory/printing.png",
  },
  {
    id: 10,
    title: "Embroidery",
    image: "/factory/embroidery.png",
  },
  {
    id: 11,
    title: "Quality Control",
    image: "/factory/quality.png",
  },
  { id: 12, title: "Packing", image: "/factory/packing.png" },
];

export default FactoryData;
