const DesignData = [
  {
    id: 1,
    code: "JSCO",
    group: "Single Jersey",
    construction: "100% cotton",
    quality: "Open end",
    count: 30.1,
    weight: 155,
  },
  {
    id: 2,
    code: "JSCC",
    group: "Single Jersey",
    construction: "100% cotton",
    quality: "combed",
    count: 30.1,
    weight: 155,
  },
  {
    id: 3,
    code: "JHCO",
    group: "heavy Jersey",
    construction: "100% cotton",
    quality: "Open end",
    count: 20.1,
    weight: 185,
  },
  {
    id: 4,
    code: "JSVL",
    group: "Viscone Lycra",
    construction: "95% viskose 5% lycra",
    quality: "Ring spun",
    count: 30.1,
    weight: 210,
  },
  {
    id: 5,
    code: "JHCR",
    group: "Heavy Jersey",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 20.1,
    weight: 185,
  },
  {
    id: 6,
    code: "JHCC",
    group: "Heavy Jersey",
    construction: "100% cotton",
    quality: "Combed",
    count: 20.1,
    weight: 185,
  },
  {
    id: 7,
    code: "JDCR",
    group: "Super Heavy Jersey",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 30.2,
    weight: 240,
  },
  {
    id: 8,
    code: "JSLR",
    group: "Lycra Jersey",
    construction: "95% cotton 5% elasthane",
    quality: "Ring spun",
    count: 30.1,
    weight: 200,
  },
  {
    id: 9,
    code: "JSLC",
    group: "Lycra Jersey",
    construction: "95% cotton 5% elasthane",
    quality: "Combed",
    count: "30.1",
    weight: 200,
  },
  {
    id: 10,
    code: "JFLR",
    group: "Lycra Jersey",
    construction: "92% cotton 8% elasthane",
    quality: "Ring spun",
    count: 30.1,
    weight: 240,
  },
  {
    id: 11,
    code: "JFLC",
    group: "Lycra Jersey",
    construction: "92% cotton 8% elasthane",
    quality: "Combed",
    count: 40.1,
    weight: 210,
  },
  {
    id: 12,
    code: "JSCR",
    group: "Single Jersey",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 30.1,
    weight: 155,
  },
  {
    id: 13,
    code: "ISCR",
    group: "Interlock",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 30.1,
    weight: 220,
  },
  {
    id: 14,
    code: "ISCC",
    group: "Interlock",
    construction: "100% cotton",
    quality: "Combed",
    count: 30.1,
    weight: 220,
  },
  {
    id: 15,
    code: "CBCR",
    group: "1x1 rib",
    construction: "100% cotton",
    quality: "Ring/Bio",
    count: 24.1,
    weight: 215,
  },
  {
    id: 16,
    code: "RSCR",
    group: "2 yarn unbrushed",
    construction: "100% cotton",
    quality: "Ring spun",
    count: "20x20",
    weight: 245,
  },
  {
    id: 17,
    code: "PSCC",
    group: "Pique",
    construction: "100% cotton",
    quality: "Combed",
    count: 30.1,
    weight: 200,
  },
  {
    id: 18,
    code: "PHCR",
    group: "Pique",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 20.1,
    weight: 240,
  },
  {
    id: 19,
    code: "PHCC",
    group: "Pique",
    construction: "100% cotton",
    quality: "Combed",
    count: 20.1,
    weight: 240,
  },
  {
    id: 20,
    code: "PSMR",
    group: "Pique",
    construction: "50% cotton 50% polyester",
    quality: "Ring spun",
    count: 30.1,
    weight: 220,
  },
  {
    id: 21,
    code: "PSLC",
    group: "Pique",
    construction: "95% cotton 5% elasthane",
    quality: "Combed",
    count: 30.1,
    weight: 220,
  },
  {
    id: 22,
    code: "PASC",
    group: "Pique/American",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 30.1,
    weight: 200,
  },
  {
    id: 23,
    code: "PSCR",
    group: "Pique",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 30.1,
    weight: 200,
  },

  {
    id: 24,
    code: "SSLR",
    group: "2 yarn fleece",
    construction: "92% cotton 8% elasthane",
    quality: "Ring spun",
    count: 30.2,
    weight: 290,
  },

  {
    id: 25,
    code: "SSMR",
    group: "2 yarn fleece",
    construction: "50% cotton 50% polyester",
    quality: "Ring spun",
    count: 20.1,
    weight: 280,
  },

  {
    id: 26,
    code: "SSCR",
    group: "2 yarn fleece",
    construction: "100% cotton",
    quality: "Ring spun",
    count: 20.1,
    weight: 280,
  },
  {
    id: 27,
    code: "SVMR",
    group: "2 yarn fleece",
    construction: "80% cotton 20%polyester",
    quality: "Ring spun",
    count: 30.2,
    weight: 240,
  },
  {
    id: 28,
    code: "MSMR",
    group: "Milano",
    construction: "50% cotton 50% polyester",
    quality: "Ring spun",
    count: 30.2,
    weight: 320,
  },
  {
    id: 29,
    code: "OLPR",
    group: "Micro Fleece",
    construction: "100 % micro polyester",
    quality: "Polyester",
    weight: 220,
  },
  {
    id: 30,
    code: "OHPP",
    group: "Polar fleece",
    construction: "100% polyester",
    quality: "Polyester",
    weight: 280,
  },
  {
    id: 31,
    code: "JA08",
    group: "Polyester",
    construction: "100% polyester",
    weight: 0,
  },
  {
    id: 32,
    code: "JA03",
    group: "Nylon",
    construction: "100% nylon",
    weight: 0,
  },
  {
    id: 33,
    code: "JA01",
    group: "Nylon",
    construction: "100% Nylon",
    weight: 0,
  },
  {
    id: 34,
    code: "JA02",
    group: "Nylon",
    construction: "55% cotton 45% nylon",
    weight: 0,
  },
  {
    id: 35,
    code: "JA04",
    group: "Polyester",
    construction: "100% Polyester",
    weight: 0,
  },
  {
    id: 36,
    code: "JA05",
    group: "Polyester",
    construction: "100% polyester -taslan",
    weight: 0,
  },
  {
    id: 37,
    code: "JA06",
    group: "Nylon",
    construction: "100% nylon",
    weight: 0,
  },
  {
    id: 38,
    code: "JA07",
    group: "Nylon",
    construction: "100% nylon",
    weight: 0,
  },
  {
    id: 39,
    code: "SH04",
    group: "Twill",
    construction: "100% cotton",
    quality: "",
    weight: 0,
  },
  {
    id: 40,
    code: "SH05",
    group: "Denim",
    construction: "100% cotton",
    quality: "ring spun",
    count: "8 ounce",
    weight: 0,
  },
  {
    id: 41,
    code: "SH01",
    group: "Oxford",
    construction: "70% cotton 30% polyester",
    quality: "",
    weight: 0,
  },
  {
    id: 42,
    code: "SH03",
    group: "Denim",
    construction: "100% cotton",
    quality: "",
    weight: 0,
  },
  {
    id: 43,
    code: "GSMS",
    group: "Gabardin",
    construction: "65% polyester 35% cotton",
    quality: "",
    weight: 245,
  },
];
export default DesignData;
