import React from "react";
import designwomen from "./women.jpg";
const Quality = () => {
  return (
    <div className="">
      <div className="mb-5 container">
        <img
          src={designwomen}
          className="img-fluid img-thumbnail"
          alt="women"
        />
      </div>
      <h1 className="my-5 title">
        Why Choose <span className="under">Us</span> ?
      </h1>
      <div className="container my-5">
        <p className="my-4">
          <strong>SERVICE :</strong> We offer the highest level of customer
          service. Our office-based enthusiastic and experienced team is always
          ready to help you and find effective and creative solution.
        </p>
        <p className="mb-4">
          <strong>EXPERIENCE :</strong> We have provided top quality garments
          for many years and this give us the experience that you probably
          looking for.
        </p>
        <p className="mb-4">
          <strong>PRICE :</strong> With our vertical integrated production and
          volume power you can't beat our prices for the quality you get.
        </p>
        <p className="mb-4">
          <strong>QUALITY :</strong> We stand behind our words, no stories, no
          excuses. Our quality control system can provide you any information
          you need, we do not leave any room for errors.
        </p>
        <p className="mb-4">
          <strong>VALUE :</strong> We have the experience and expertise, but we
          like to do more! Add a dash of creativity and what you get is
          satisfied customer.
        </p>
      </div>
    </div>
  );
};

export default Quality;
