import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { FaList } from "react-icons/fa";
import logo from "../Apparel_hut Final-01.png";
const Header = () => {
  const bg = { backgroundColor: "#081439", opacity: "1" };
  return (
    <Navbar
      className="py-0"
      collapseOnSelect
      expand="lg"
      style={bg}
      variant="dark"
      fixed="top"
    >
      <Navbar.Brand as={Link} to="/">
        <img className="p-0 m-2" alt="" src={logo} width="100" />
      </Navbar.Brand>
      <Navbar.Toggle />

      <Navbar.Collapse>
        <Nav className="ms-auto px-3 text-start">
          <Nav.Link active as={Link} to="/home">
            Home
          </Nav.Link>
          <Nav.Link active as={Link} to="/profile">
            Profile
          </Nav.Link>
          {/* <Nav.Link active as={Link} to="/quality">
            Quality
          </Nav.Link> */}
          <Nav.Link active as={Link} to="/design">
            Design & Development
          </Nav.Link>
          <Nav.Link active as={Link} to="/factory">
            Factory
          </Nav.Link>

          <Nav.Link active as={Link} to="/products">
            Products
          </Nav.Link>
          {/* <Nav.Link active as={Link} to="/sidebar">
            Sidebar
          </Nav.Link> */}
          <Nav.Link active as={Link} to="/register">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
