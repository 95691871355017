import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Register from "./component/Contact/Register/Register";
import Design from "./component/Design/Design";
import Sidebar from "./component/Drawer/Sidebar/Sidebar";
// import Wardrobe from "./component/Drawer/Wardrobe";
import Factory from "./component/Factory/Factory";
import Banner from "./component/home/Banner/Banner";
import Home from "./component/home/Home/Home";
import Products from "./component/Products/Products";
import Profile from "./component/Profile/Profile";
import Quality from "./component/Quality/Quality";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";
import Footer from "./component/Shared/Footer/Footer";
import Header from "./component/Shared/Header/Header";
import Notfound from "./Notfound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header></Header>
        <Banner></Banner>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/quality" element={<Quality />} />
          <Route path="/design" element={<Design />} />
          <Route path="/factory" element={<Factory />} />
          <Route path="/products" element={<Products />} />
          <Route path="/sidebar" element={<Sidebar />} />
          {/* <Route path="/wardrobe" element={<Wardrobe />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
