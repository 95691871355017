import React from "react";

const Notfound = () => {
  return (
    <div className="container">
      <h1
        className="title rounded bg-body shadow d-inline-block"
        style={{ padding: "50px", fontSize: "80px", color: "crimson" }}
      >
        404
      </h1>
      <h2 className="title">Page not Found</h2>
    </div>
  );
};

export default Notfound;
